import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa el CSS de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Importa el JS de Bootstrap
import Home from './pages/home';
import Form from './pages/form';
import Scratch from './pages/scratch';
import Validate from './pages/validate';
import ProtectedRoute from './auth/ProtectedRoute';
import Dashboard from './pages/dashboard';

const App = () => {
    const [fromHome, setFromHome] = useState(false);
    const [fromForm, setFromForm] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            setFromHome(true);
        } else if (location.pathname === '/form') {
            setFromForm(true);
        }
    }, [location.pathname]);

    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />} />
            <Route path="/validate" element={<Validate />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
                path="/form"
                element={
                    <ProtectedRoute isAllowed={fromHome} redirectPath="/">
                        <Form />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/scratch/:id"
                element={
                     <ProtectedRoute isAllowed={fromForm} redirectPath="/form">
                        <Scratch />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Router>
        <App />
    </Router>
    // </React.StrictMode>
);

