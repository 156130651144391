import React, { useEffect, useState } from 'react';
import { url } from '../api/constants';
import LogoMorado from '../assets/img/LogoMorado.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import Switch from 'react-switch';


const Dashboard = () => {
  // const [listData, setListData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [itemsData, setitemsData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [activeItems, setActiveItems] = useState({});

  // Fetching data for the list
  // useEffect(() => {
  //   fetch(`${url}/clients`)
  //     .then(response => response.json())
  //     .then(data => setListData(data.data))
  //     .catch(error => console.error('Error fetching list data:', error));
  // }, []);

  // Fetching data for the dashboard
  useEffect(() => {
    fetch(`${url}/clients/all`)
      .then(response => response.json())
      .then(data => setDashboardData(data.data))
      .catch(error => console.error('Error fetching dashboard data:', error));

    fetch(`${url}/getitems/all`)
      .then(response => response.json())
      .then(data => {
        setitemsData(data.data);
        const initialActiveItems = data.data.reduce((acc, item) => {

          if (item.isActive === true) {
            acc[item._id] = true;
          } else {
            acc[item._id] = false;
          }
          return acc;
        }, {});
        setActiveItems(initialActiveItems);

      })
      .catch(error => console.error('Error fetching dashboard data:', error));
  }, []);


  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000); // 1 segundo
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleReportar = async (id) => {
    // e.preventDefault();
    try {
      // Simulación de llamada a backend
      const response = await fetch(`${url}/update/whitelist/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ whitelist: true }),
      });

      const data = await response.json();
      console.log(data);

      if (response.status === 200) {
        setDashboardData(data.data);
      }

    } catch (err) {
      //setError('Error al conectar con el servidor.');
    }

  };

  const handleToggleActive = (id) => {
    fetch(`${url}/update/item/active/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        active: !activeItems[id]
      })
    })
      .then(response => response.json())
      .then(data => {
        setitemsData(data.data);
        const initialActiveItems = data.data.reduce((acc, item) => {
          console.log(item);
          if (item.isActive === true) {
            acc[item._id] = true;
          } else {
            acc[item._id] = false;
          }
          return acc;
        }, {});
        setActiveItems(initialActiveItems);
      })

    // setActiveItems(prevState => ({
    //   ...prevState,
    //   [id]: !prevState[id]
    // }));

  };


  return (
    <div className="container mt-3 p-3" >
      <div className="text-center mb-3">
        <img src={LogoMorado} alt="Logo" className="img-fluid" style={{ maxWidth: '150px' }} />
        <hr />
      </div>
      <div className="row">
        {/* List Section */}
        <div className="col-md-6 mt-2">
          <h4>Usuario pendientes</h4>
          <ul className="list-group">
            {dashboardData && dashboardData.filter((u) => u.whitelist !== true).map(item => (<>
              <div key={item.id} className="list-group-item d-flex  align-items-center">
                <div className='col-md-8'>
                  <span>{item.email}</span>
                </div>
                <div className='col-md-2'>
                  <CopyToClipboard text={item.email} onCopy={() => setCopied(true)}>
                    <button className="btn btn-link">
                      <FaCopy style={{ color: '#8400FF' }} />
                    </button>
                  </CopyToClipboard>
                </div>
                <div className='col-md-2'>
                  <button className="btn btn-primary btn-sm" onClick={() => handleReportar(item._id)} style={{ background: '#8400FF', border: '#8400FF' }}>
                    Reportar
                  </button>
                </div>
              </div>

            </>

            ))}
          </ul>
          {copied && <div className="alert alert-success mt-2">¡Correo copiado al portapapeles!</div>}
        </div>

        {/* Dashboard Section */}
        {dashboardData && itemsData && (


          <div className="col-md-6 mt-4">
            <h4> Dashboard Clientes</h4>
            <div className="card">
              <div className="card-body">
                <p>Clientes participando: {dashboardData.length}</p>
                <p>Premios reclamados: {dashboardData.filter((u) => u.delivery === true).length}</p>
                <p>Clientes en WL: {dashboardData.filter((u) => u.whitelist === true).length}</p>
                <p>Clientes pendientes por WL: {dashboardData.filter((u) => u.whitelist !== true).length}</p>
              </div>
            </div>

            <h4 className='mt-4'> Premios Mostrados  </h4>
            <div className="card">
              <div className="card-body">
                <div className="list-group-item d-flex  align-items-center">
                  <div className='col-md-6'>
                    <p>Jomi Cup: {dashboardData.filter((u) => u.item === 'Jomi Cup').length}</p>
                    <p>Jomi Bag: {dashboardData.filter((u) => u.item === 'Jomi Bag').length}</p>
                    <p>Jomi Popsocket: {dashboardData.filter((u) => u.item === 'Jomi Popsocket').length}</p>
                  </div>

                </div>
              </div>
            </div>

            <h4 className='mt-4'> Inventario</h4>
            <div className="card">
              <div className="card-body">
                <div className="list-group-item d-flex  align-items-center">
                  <div className='col-md-6'>
                    {itemsData.filter((u) => u.name === 'Jomi Cup').map(item => (
                      <div key={item._id} className="d-flex align-items-center">
                        <p style={{ marginRight: "20px", marginTop: "15px" }}>Jomi Cup: {item.quantity} de {item.initial}</p>
                        <Switch
                          onChange={() => handleToggleActive(item._id)}
                          checked={activeItems[item._id]}
                          onColor="#8400FF" // Color cuando está activo
                        />
                      </div>
                    ))}
                    {itemsData.filter((u) => u.name === 'Jomi Bag').map(item => (
                      <div key={item._id} className="d-flex align-items-center">
                        <p style={{ marginRight: "20px", marginTop: "15px" }}>Jomi Bag: {item.quantity} de {item.initial}</p>
                        <Switch
                          onChange={() => handleToggleActive(item._id)}
                          checked={activeItems[item._id]}
                          onColor="#8400FF" // Color cuando está activo
                        />
                      </div>
                    ))}
                    {itemsData.filter((u) => u.name === 'Jomi Popsocket').map(item => (
                      <div key={item._id} className="d-flex align-items-center">
                        <p style={{ marginRight: "20px", marginTop: "15px" }}>Jomi Popsocket: {item.quantity} de {item.initial}</p>
                        <Switch
                          onChange={() => handleToggleActive(item._id)}
                          checked={activeItems[item._id]}
                          className=''
                          onColor="#8400FF" // Color cuando está activo

                        />
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default Dashboard;
