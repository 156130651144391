//import './App.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/home.css';
import Image1 from '../assets/img/1.png';
import Image2 from '../assets/img/2.png';
import Image3 from '../assets/img/3.png';
import Logo from '../assets/img/LogoCompleto.png';
import Participa from '../assets/img/Participa.png';
import Instagram from '../assets/img/Instagram.svg';
import Web from '../assets/img/Web.svg';

function Home() {

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleImageClick = () => {
        setShowModal(true);
        //navigate('/form');
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (<>
        <div style={{ backgroundColor: "#8400FF", textAlign: "center", padding: "25px" }}>

            <div className="col-lg-12">
                <div className="phone-frame-home mx-auto">
                    <div className="phone-notch"></div>
                    <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Image1} className="d-block w-100" alt="Slide 1" />
                            </div>
                            <div className="carousel-item">
                                <img src={Image2} className="d-block w-100" alt="Slide 1" />
                            </div>
                            <div className="carousel-item">
                                <img src={Image3} className="d-block w-100" alt="Slide 3" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="base-logo">
                    <div className="phone-notchii" >
                        <img src={Logo} style={{ width: "200px" }} alt="Slide 1" />
                    </div>
                </div>

            </div>
            <div className="container text-center">
                <label style={{ color: "#fff", fontSize: "19px", fontWeight: "bold" }}>¡Unete a nuestra comunidad!</label>
            </div>
            <div className="container text-center" style={{ marginTop: "5px" }}>
                <label style={{ color: "#fff", fontSize: "14px" }}>Estaremos premiando a nuestro primeros usuarios.</label>
            </div>
            <div className="container text-center" style={{ marginTop: "5px", marginBottom: "50px" }}>
                <label style={{ color: "#fff", fontSize: "14px" }}>No dejes pasar esta oportunidad...</label>
            </div>
        </div>

        <div className="container text-center panelblanco-home">

            <img src={Participa} style={{ width: "350px", cursor: "Pointer" }} className="botonamarillo" alt="Slide 1" onClick={handleImageClick} />

        </div>

        <div className="container text-center">

            <footer className="footer-home-home">
                <div className="container text-center" style={{ marginBottom: "0px" }}>
                    <div className="footer-icons-home">
                        <div className="footer-icon-home">
                            <img src={Web} alt="web icon" />
                            <a href="https://jomi.app/" className="footer-link"> jomi.app</a>
                        </div>
                        <div className="footer-icon-home">
                            <img src={Instagram} alt="instagram icon" />
                            <a href="https://www.instagram.com/jomi.app" className="footer-link"> @jomi.app</a>
                        </div>
                    </div>
                </div>

                <p className="footer-legal-home">
                    <strong>Somos JOMI C.A</strong> &copy; 2024. Todos los derechos reservados.
                </p>
            </footer>
        </div>

        {showModal && (
            <div className="modal show d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">¡Importante!</h5>
                            {/* <button type="button" className="close" onClick={handleCloseModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <p>El concurso estará disponible a partir del Jueves 19 de septiembre del 2024.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" style={{ background: "#8400FF", borderColor: "#8400FF" }} onClick={handleCloseModal}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    )
}

export default Home;
