import React, { useState } from 'react';
import LogoMorado from '../assets/img/LogoMorado.png';
import { url } from '../api/constants';


const EmailValidationForm = () => {
    const [email, setEmail] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState('');
    const [id, setId] = useState('');

    const handleCanje = async (e) => {
        e.preventDefault();
        try {
            // Simulación de llamada a backend
            const response = await fetch(`${url}/update/delivery/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ delivery: true }),
            });

            const data = await response.json();


            if (response.status === 200) {
                setUserInfo(data.data);
            }

        } catch (err) {
            setError('Error al conectar con el servidor.');
        }
    };

    const handleButton = () => {
        setEmail('');
        setUserInfo(null);
        setError('');
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Simulación de llamada a backend
            const response = await fetch(`${url}/validate/client`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ item: email }),
            });

            const data = await response.json();
            console.log(data);

            if (response.status === 200) {
                setId(data.data._id);
                setUserInfo(data.data);
            } else {
                setError(data.message || 'No se encontró el usuario.');
            }
        } catch (err) {
            setError('Error al conectar con el servidor.');
        }
    };

    return (
        <div className="container mt-5" style={{ padding: "20px" }}>
            <div className="row justify-content-center">
                <div className="col-md-6">

                    <div className="text-center mb-4">
                        <img src={LogoMorado} alt="Logo" className="img-fluid" style={{ maxWidth: '150px' }} />
                    </div>
                    <div className="card shadow-sm">
                        <div className="card-body text-center">
                            <h5 className="card-title text-center">Validar Premio</h5>
                            <hr></hr>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" style={{ marginBottom: "10px", color: "gray" }}></label>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Correo Electrónico o Codigo Premio"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        style={{ padding: '1rem', }}
                                    />
                                </div>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#8400FF', border: "#8400FF" }}>
                                    Validar
                                </button>
                                <button type="button" onClick={handleButton} className="btn btn-warning" style={{ marginLeft: "10px" }}>
                                    Limpiar
                                </button>
                            </form>
                        </div>
                    </div>

                    {userInfo && (
                        <div className="card mt-4 shadow-sm">
                            <div className={'card-body ' + (userInfo.delivery === true ? 'bg-danger bg-opacity-25' : 'bg-success bg-opacity-25')} style={{ padding: "20px" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="card-title">Ficha del Usuario</h5>
                                    {userInfo && !userInfo.delivery ? (
                                        <button className="btn btn-primary" style={{ backgroundColor: '#8400FF', border: "#8400FF" }} onClick={handleCanje} >Canjear</button>
                                    ) : ""
                                    }
                                </div>
                                <hr></hr>
                                <p><strong>Nombre:</strong> {userInfo.name}</p>
                                <p><strong>Correo:</strong> {userInfo.email}</p>
                                <p><strong>Teléfono:</strong> {userInfo.phone === "" ? "No Registrado" : userInfo.phone}</p>
                                <p><strong>Fecha:</strong>  {formatDate(userInfo.created_at)}</p>
                                <p><strong>Premio:</strong> {userInfo.item}</p>
                                <p><strong>Interes:</strong> {userInfo.intentions.map((u) => u + " - ")}</p>
                                <p><strong>Codigo:</strong> {userInfo._id}</p>
                                <p><strong>Canjeado:</strong> {userInfo.delivery === true ? "Si" : "No"}</p>
                                <p><strong>Fecha Canje:</strong>  {userInfo.delivery === true ? formatDate(userInfo.modified_at) : '-'}</p>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailValidationForm;
